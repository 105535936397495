.hero {
	&__content {
		color: $hero-content-main-color;

		.scroll-to-video {
			@include fluidsize($font-sizes-nm);
			padding-left: 0;
			display: flex;
			align-items: center;
			background: transparent;

			svg {
				width: 32px;
				height: 32px;
				margin-right: 1rem;
				overflow: visible;
			}
		}
	}
}
