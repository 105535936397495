.sticky-link {
	$root: &;

	&__link {
		@include responsify($spacing-md, bottom);
	}

	&--to-top {
		.page--scrolled-deep & {
			opacity: 0.7;
		}
	}
}
