.icons-grid {
	&__top-content {
		@include container();
		display: flex;
		flex-flow: column;
	}

	&__lead-text {
		text-align: center;
	}

	&__sub-text {
		text-align: center;
	}

	&__single {
		//justify-self: center !important;
	}
}
