// font-sizes: add as many as you want and also as many steps as you want. They are used step by step for responsive screens, beginning with the smallest resolution
// example: [default size and size for first entry in responsive breakpoints list], [size for second entry upwards], [size for third entry upwars] ,...
// example: [default size and size for first entry in responsive breakpoints list], [size for second entry upwards], [size for third entry upwars] ,...
$font-sizes-xs: 0.75rem, 0.75rem, 0.875rem, 0.875rem, 0.875rem;
$font-sizes-sm: 1rem, 1rem, 1rem, 1rem, 1rem; // small text
$font-sizes-nm: 1rem, 1rem, 1.25rem, 1.3rem, 1.375rem, 1.375rem, 1.375rem; // standard text
$font-sizes-md: 1.25rem, 1.5rem, 1.625rem, 1.625rem, 1.875rem;
$font-sizes-lg: 1.5rem, 1.875rem, 2.25rem, 2.25rem, 3.125rem;
$font-sizes-xl: 1.5rem, 1.5rem, 1.5rem, 1.5rem, 2rem, 2rem, 2.5rem; //h3
$font-sizes-xxl: 2rem, 2rem, 3.2rem, 4.375rem, 4.375rem; //h2
$font-sizes-xxxl: 3.75rem, 3.75rem, 5rem, 5rem, 5rem, 5rem, 5rem, 6.25rem, 6.25rem; //h1
$font-sizes-nav: 3.4375rem, 3.4375rem, 3.4375rem, 1.575rem, 1.575rem, 1.575rem, 1.575rem;
$font-sizes-marquee: 6rem, 6rem, 8rem, 12rem, 14rem, 18.75rem, 18.75rem, 18.75rem;

$font-size-filter: 1rem, 1rem, 1.2rem, 1.27rem, 1.35rem, 1.35rem, 1.35rem;

// define line heights, to responsify them later
$line-height-sm: 1.15, 1.15, 1.2, 1.2;
$line-height-md: 1.2, 1.25, 1.375;
$line-height-lg: 1.2, 1.35, 1.5, 1.5;
$line-height-xl: 1.68, 1.8, 2;

// font families
$font-family-sans-serif: system, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", "San Francisco", "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, Oxygen-Sans, Ubuntu, Cantarell, "Lucida Grande", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-serif:      Constantia, "Lucida Bright", Lucidabright, "Lucida Serif", Lucida, "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif", Georgia, Cambria, "Hoefler Text", Utopia, "Liberation Serif", "Nimbus Roman No9 L Regular", "Times New Roman", Times, serif;
$font-family-monospace:  Menlo, Monaco, Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", "Courier New", Courier, monospace;
$font-family-base:       'Avenir Next Guave', sans-serif;

$font-weight-light: 200;
$font-weight-normal: 400;
$font-weight-italic: 400;
$font-weight-bold: 500;

$font-style-normal: normal;
$font-style-italic: italic;

// font-face-helper
@mixin font-face($name, $filename, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($fonts-base-path + $filename + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}

// ADD FONTS HERE with mixin
// example
// @include font-face('proxima-nova-web', 'proxima-nova-light', normal, null, woff2 woff);
// @include font-face('proxima-nova-web', 'proxima-nova-bold', bold, null, woff2 woff);

@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=21e2d220-4a47-47b9-84df-3289bbc622b0");
@font-face{
	font-family:"Avenir Next Guave";
	font-weight: 200;
	src:url("#{$fonts-base-path}670c4af6-0f8f-4dfa-89d7-0213a1f77b7d.woff2") format("woff2"),url("#{$fonts-base-path}b25f4dd5-5498-47ad-bf13-ed8d085f0ace.woff") format("woff");
}
@font-face{
	font-family:"Avenir Next Guave";
	font-weight: 200;
	font-style: italic;
	src:url("#{$fonts-base-path}f5ab1a5d-da17-451b-9f60-c9d132b69cb8.woff2") format("woff2"),url("#{$fonts-base-path}15076b53-6450-4913-a4dd-af93d4e59311.woff") format("woff");
}
@font-face{
	font-family:"Avenir Next Guave";
	font-weight: 400;
	src:url("#{$fonts-base-path}c4691e94-080d-4452-8f9f-8b3db6b68d55.woff2") format("woff2"),url("#{$fonts-base-path}a1adabe6-efe5-40b7-b619-48111c8ff883.woff") format("woff");
}
@font-face{
	font-family:"Avenir Next Guave";
	font-weight: 400;
	font-style: italic;
	src:url("#{$fonts-base-path}f07f5ab2-8ecc-416d-afa7-b73dbea95907.woff2") format("woff2"),url("#{$fonts-base-path}3a03e3a3-baa3-4079-aa61-d2ec2a35bea3.woff") format("woff");
}
@font-face{
	font-family:"Avenir Next Guave";
	font-weight: 500;
	src:url("#{$fonts-base-path}6afe4676-059a-4aa8-b891-29856bbcba22.woff2") format("woff2"),url("#{$fonts-base-path}a9817c16-2a79-49bf-8543-6c846d73a52f.woff") format("woff");
}
@font-face{
	font-family:"Avenir Next Guave";
	font-weight: 500;
	font-style: italic;
	src:url("#{$fonts-base-path}e9540909-1e28-4c00-9423-10e43c828f31.woff2") format("woff2"),url("#{$fonts-base-path}691d198c-3c78-40b5-9ce3-c92eda0bab0f.woff") format("woff");
}
