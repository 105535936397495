.video-gallery {
	background: $color-dark-coal;

	&__wrapper {
		@include responsify($wrapper-padding, 'padding');
	}

	&__grid {
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 2rem;
		align-items: start;
		justify-items: center;
		height: 100%;

		@include media($breakpoint-desktop-up) {
			grid-template-columns: repeat(2, 1fr);
			grid-column-gap: 2rem;
		}

		.video {
			@include responsify($spacing-no, padding);
			width: 100%;
		}

		.video__wrapper {
			margin: 0;
			aspect-ratio: 2/1;
			padding-bottom: 0;
			height: 100%;
		}
	}

	&__text {
		@include responsify($spacing-xs, margin-top);
		@include fluidsize($font-sizes-xl);
		font-weight: $font-weight-bold;
	}
}
