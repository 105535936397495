.header {
	color: $header-font-color;

	.main-logo {
		color: $text-color;

		&__solo {
			color: $color-motion;

			.header--dark & {
				color: $color-motion;
			}
		}

		&__text {
			&-guave {
				color: $color-motion;

				.header--dark & {
					color: $color-motion;
				}
			}

			&-brand {
				color: $text-color;

				.header--red & {
					color: $text-color;
				}

				* {
					fill: currentColor;
				}
			}
		}
	}
}

