.credits {
	@include bgColors();

	&__wrapper {
		@include container();
	}

	&__title {
		@include fluidsize($font-sizes-xxl);
		text-align: center;
	}

	&__list {
		@include container('medium', false);
		display: flex;
		flex-flow: column;
		align-items: center;
		color: $color-white;

		&-single {
			display: flex;
			flex-flow: row;
			justify-content: center;
			gap: 2rem;
			width: 100%;

			@include media('>=md') {
				gap: 3rem;
			}
		}
	}

	&__type,
	&__name {
		flex: 1;
	}

	&__type {
		display: flex;
		flex-flow: column;
		font-weight: $font-weight-bold;
		text-align: right;
	}

	&__name {
		@include responsify($spacing-sm, margin-bottom);
		display: flex;
		flex-flow: column;
		text-align: left;
	}
}
