.team-motion {
	$team: &;
	$gap: 3em;
	@include bgColors();
	background: $color-dark-coal;

	&__wrapper {
		@include responsify($wrapper-padding, 'padding-left');
		@include responsify($wrapper-padding, 'padding-right');
		@include responsify($wrapper-padding, 'padding-bottom');
		@include responsify($wrapper-padding, 'padding-top');
	}

	&__title {
		text-align: center;
		@include responsify($wrapper-padding, 'padding-bottom');
		@include responsify($spacing-no, 'padding-top');
		@include responsify($spacing-no, 'margin-bottom');
	}

	&__list {
		@include responsify($wrapper-padding, gap);
		display: flex;
		flex-flow: column;
		//gap: $gap;

		@include media('>=sm') {
			flex-flow: row wrap;
		}
	}

	&__member {
		flex: 0 0 100%;
		max-width: 100%;

		// make items stay same size on 50/50 with retracted column gap on responsive;
		//value ($wrapper-padding-list) has to be adjusted manually to start at a desired breakpoint
		@each $key, $val in $wrapper-padding-list {
			@include media('>=#{$key}') {
				flex: 0 0 calc((50% - (#{$val}) / 2));
				max-width: calc((50% - (#{$val}) / 2));
			}
		}
	}

	&__image {
		position: relative;
		aspect-ratio: 16/9;
		overflow: hidden;

		img {
			@include full-height-media();
		}
	}

	&__description {
		@include fluidsize($font-sizes-xl);
		@include responsify($spacing-md, padding-top);
		display: flex;
		flex-flow: column;
		align-items: flex-start;

		.team {
			&-name {
				font-weight: $font-weight-bold;
			}

			&-title {
				@include fluidsize($font-sizes-nm);
			}

			&-quote {
				@include responsify($spacing-md, margin-top);
				@include fluidsize($font-sizes-nm);
			}
		}
	}
}
