:root {
	--focus-colors: #{$color-focus};
}

$body-bg: $color-coal;
$text-color: $color-white;
$text-color-highlight: $color-motion;

$link-color: currentColor;
$link-decoration: none;
$link-hover-color: currentColor;
$link-hover-decoration: none;

// buttons configurable variables
$button-font: bold;
$button-transition: 0.3s $ease-out-quad;
$button-padding-vertical: 0.5em;
$button-padding-horizontal: 1em;
$button-font-size: $font-sizes-sm;
$button-border: 0;
$button-border-radius: 0;
$button-box-shadow: none;
$button-small-padding-vertical: 0.5em;
$button-small-padding-horizontal: 1em;
$button-small-font-size: $font-sizes-sm;
$button-small-border: 0;
$button-small-border-radius: 0;
$button-small-box-shadow: none;

$button-color: $color-white;
$button-primary-bg: $color-primary;
$button-primary-bg-hover: transparent;
$button-primary-color: $color-white;
$button-primary-color-hover: currentColor;
$button-secondary-bg: $color-interactive;
$button-secondary-bg-hover: darken($button-secondary-bg, 5%);
$button-secondary-color: $color-white;
$button-secondary-color-hover: $color-white;

// form fields configuragtion
$field-maxwidth: 31.25rem; // fields will not get bigger than this, please do not use none, use a value here
$field-minwidth: 12.5rem; // fields will not get smaller than this, please do not use none, use a value here
$field-padding: 1rem; // standard padding of fields, non responsive, but you can use a spacing var here (single value, no list)
$field-spacing: 0.375rem; // standard spacing between label and text, non responsive but you can use a spacing var here (single value, no list)
$field-linecolorvalid: $color-white;
$field-linecolorinvalid: $color-error-red;
$field-error: $color-error-red;
$field-error-font-style: bold;
$field-success: $color-interactive;
$field-success-font-style: bold;
$field-containerbgcolor: $color-dark-coal;
$field-containerbg-hover: $color-grey-ultralight;
$field-color: $color-white;
$field-color-active: $color-white;
$field-label-color: $color-grey;
$field-label-color-active: $color-grey;
$field-fontsize: $font-sizes-nm;
$field-line-height: 1.2;
$field-border-radius: 3px;
$field-additional-color: rgba($text-color, 0.4);
$field-label-focus-background: $color-dark-coal;
$button-form-submit-color: $color-white;
$button-form-submit-weight: $font-weight-bold;
$field-font-weight: $font-weight-bold;
$field-label-text-color: $color-white;

$range-input-track-color-left: $color-interactive;
$range-input-track-color-right: $color-grey-light;
$range-input-thumb-color: $color-interactive;

$media-reveal-color: $color-interactive;

// custom variables
$page-loader-bg: $color-motion;
$page-loader-color: $color-white;
$color-logo-fat-text: $color-motion;
$footer-background-color: $color-motion;
$header-font-color: $color-white;
$mobile-navi-color: $color-white;
$logo-brand-color: $color-white;
$logo-main-color: $color-white;
$hero-content-main-color: $color-white;
