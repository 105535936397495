.showcases {
	@include responsify($wrapper-padding, padding);
	display: flex;
	flex-flow: column;
	gap: 1rem;
	background: $color-dark-coal;

	@include media('>=md') {
		gap: 2rem;
		flex-flow: row wrap;
		align-items: flex-start;
		justify-content: space-between;
	}

	&-expander {
		@include responsify($wrapper-padding, padding-left);
		@include responsify($wrapper-padding, padding-right);
		@include responsify($spacing-md, padding-top);
		@include responsify($spacing-md, padding-bottom);
		display: flex;
		flex-flow: column;
		gap: 1rem;
		transition: gap 0.3s $ease-out-quad;

		@include media($breakpoint-desktop-up) {
			flex-flow: row;
			gap: 1rem 2.5rem;
		}

		&.collapsible {
			&.collapsible--collapsed {
				@include media($breakpoint-desktop-down) {
					gap: 0;
				}
			}

			&:not(.collapsible--collapsed) {
				.collapsible__trigger {
					svg {
						transform: rotate(270deg);
					}
				}
			}
		}
	}

	&__content {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-flow: row wrap;
		gap: 1rem 2.5rem;

		.collapsible.collapsible--collapsed & {


			&.collapsible__content {
				@include media($breakpoint-desktop-up) {
					max-height: 100% !important;
				}
			}
		}
	}

	&-filter {
		&__info {
			&--mobile {
				@include media($breakpoint-desktop-up) {
					display: none;
				}

				@include media($breakpoint-desktop-down) {
					display: flex;
					flex-flow: row;
					align-items: center;
					justify-content: space-between;
					padding: 1rem 0.6rem;
					background: $color-dark-coal;
				}

				svg {
					width: 1em;
					height: 1em;
					transition: 0.3s $ease-out-quad;
					transform: rotate(90deg);
				}

				.filter-title {
					display: flex;
					align-items: flex-start;
					column-gap: 0.3em;
				}

				.red-active-dot {
					display: none;
					width: 6px;
					height: 6px;
					border-radius: 100px;
					background: $color-motion;

					&.active {
						display: block;
					}
				}
			}

			&--desktop {
				@include media($breakpoint-desktop-down) {
					display: none;
				}
			}

			&--invisible {
				display: none;
			}

			&.collapsible__trigger {
				@include media($breakpoint-desktop-down) {
					cursor: pointer;
				}
			}
		}

		&__type {
			flex: 0 0 100%;
			position: relative;
			display: inline-flex;
			align-items: center;

			@include media('>=xs') {
				flex: 0 1 auto;
			}

			input {
				margin: 0;
				appearance: none;
			}

			label {
				@include fluidsize($font-size-filter);
				display: flex;
				align-items: center;
				cursor: pointer;
				transition: 0.3s $ease-in-out;

				&:hover {
					color: $color-motion;
				}

				svg {
					@include responsify($spacing-xxs, margin-right);
					max-width: 1.55em;
					width: 100%;
					height: 1.55em;

					.checkmark {
						opacity: 0;
						visibility: hidden;
					}
				}
			}

			&.active {
				label {
					color: $color-motion;
				}

				svg {
					.checkmark {
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}

		&__break {
			display: none;

			@include media('>=sm') {
				flex-basis: 100%;
				height: 0;
				display: block;
			}
		}
	}
}

.showcase {
	&__content {
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: flex-start;

		&--grey {
			background: $color-dark-coal;
		}

		.showcase-link {
			@include responsify($spacing-sm, margin-top);
		}

		@include media($breakpoint-desktop-up) {
			aspect-ratio: 16/9;
		}
	}

	&-single {
		position: relative;
		flex: 0 0 100%;
		display: block;
		max-width: 100%;
		overflow: hidden;
		opacity: 0;
		animation: ascend 0.3s forwards;

		@include media('>=md') {
			flex: 0 0 calc(50% - 1rem);
			max-width: 50%;
		}

		a {
			@include reset-link();
			display: flex;
			align-items: flex-end;
		}

		&__wrapper {
			@include gradient-bg($position: absolute);
			inset: auto auto 0 0;
			width: 100%;
		}

		&__content,
		&__image {
			flex: 0 0 100%;
			max-width: 100%;
		}

		&__content {
			@include responsify($spacing-lg, padding);
			align-self: flex-start;
			width: 100%;
			margin: 0;
			z-index: 999;
		}

		&__title {
			@include fluidsize($font-sizes-xl);
		}

		&__subtitle {
			@include fluidsize($font-sizes-nm);
			margin-bottom: 0;
		}

		&__image {
			aspect-ratio: 16/9;

			img {
				@include full-height-media();
			}
		}

		&.remove-filter-type {
			display: none;
		}
	}

	&__details {
		&--logo {
			align-items: center;
			justify-items: center;
		}

		.customer-logo {
			width: 35%;
			height: auto;
		}
	}
}

.nothing-found {
	display: none;

	&--show {
		display: block;
		animation: ascend 0.3s forwards;
	}
}

@keyframes ascend {
	0% {
		transform: translateY(2rem);
		opacity: 0
	}
	100% {
		transform: translateY(0);
		opacity: 1
	}
}
