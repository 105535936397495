.mobile-navigation {
	color: $mobile-navi-color;
}

.navigation-footer {
	.nav-main {
		&__nav {
			$nav: '.nav-main__nav';

			&:hover {
				#{$nav}-item {
					&:hover {
						#{$nav}-item-link {
							color: currentColor;
						}
					}
				}
			}

				/* active behavior */
				&:has(.active) {
					#{$nav}-item-link {
						&.active {
							color: currentColor;
						}
					}
			}
		}
	}
}
