// THIS FILE MAPS THE BASIC CLASSES AND SEMANTICS OF THIS BOILERPLATE TO THE PROJECTS CLASSES OR SEMANTICS - YOU HAVE TO ADJUST THIS FILE ACCORDING YOUR NEEDS
// debug
.cto-toolbar.cto-toolbar--visible {
	z-index: 9999999999999;
}

// Basic FONT  usage
body,
input,
select,
td,
textarea,
th,
textarea,
input,
select,
button {
	@include fluidsize($font-sizes-nm);
	@include responsify($line-height-lg, 'line-height');
	@include font('normal');
}

body {
	background: $body-bg;
	color: $text-color;
}

.highlight-text {
	.flexible-element__content--red & {
		color: $color-coal;
	}
}

//selection
  
::selection {
    color: $color-white;
    background: $color-motion;
}
 