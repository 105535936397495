
// primary Guave Motion colors
$color-motion: #EA5B54;
$color-highlight: $color-motion;
$color-coal: #2C2C2C;
$color-dark-coal: #181818;

// the rest
$color-primary: $color-white;
$color-secondary: $color-motion;

// focus-visible, focus color
$color-focus: $color-motion


